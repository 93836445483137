/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import ComponentsMapper from '../src/helpers/components-mapper';
import Layout from '../src/components/Layout';
import {
    getPageContentTypes,
    getPage,
    getHeader,
    getFooter,
    getProductCategories,
    getArticleCategories,
    getAllPages,
} from '../src/adapters/contentful.adapter';
import buildComponentQuery from '../src/helpers/buildPageQuery';
import { } from '../src/redux/reducers/SurveyQuestionsSlice';

const PageComponents = ({ data, pageInfo }) => data.map((props, index) => <ComponentsMapper {...props} pageInfo={pageInfo} key={index} />);
const Page = (props) => {
    const { header, footer, pageInfo, classname, pageType } = props;

    return (
        <div>
            <Layout header={header} footer={footer} pageInfo={pageInfo} pageType={pageType}>
                <div className={`content-wrapper ${classname || ''}`}>
                    <PageComponents data={props.content} pageInfo={pageInfo} />
                </div>
            </Layout>
        </div>
    );
};

Page.getInitialProps = async ({ query, reduxStore }) => {
    const { pageId, slug } = query;

    const pageContentTypes = await getPageContentTypes(pageId);
    const pageComponentsQuery = buildComponentQuery(pageContentTypes);
    const pageType = pageContentTypes?.filter((type) => type && type.__typename === 'ArticleList');
    const limit = pageType && pageType.length ? 4 : 11;
    
    const page = await getPage(pageId, pageComponentsQuery, limit);
    const header = await getHeader();
    const footer = await getFooter();
    const shouldFetchArticles = includes(['/sitemap/'], slug);   
    if (shouldFetchArticles) {
        const articleCategories = await getArticleCategories();
        reduxStore.dispatch({ type: 'FETCHED_ARTICLE_CATEGORIES', payload: articleCategories });
    }

    // fetch slugs with category names for breadcrumbs
    const allPages = await getAllPages();
    reduxStore.dispatch({ type: 'FETCHED_ALL_PAGES', payload: allPages });

    const content = page?.contentCollection?.items || [];
    return {
        header,
        footer,
        content,
        pageInfo: page && page.seoHead,
        classname: page && page.classname,
        pageType,
    };
};

Page.propTypes = {
    content: PropTypes.array,
    footer: PropTypes.object,
    header: PropTypes.object,
    pageInfo: PropTypes.object,
    classname: PropTypes.string,
    pageType: PropTypes.array,
};

export default Page;
