const buildComponentsQuery = (contentTypes) => {
    let componentQuery = '';
    const contentTypeBuild = []
    contentTypes?.forEach((contentType) => {
        let index = contentTypeBuild.indexOf(contentType?.__typename);
        if(index === -1) {
            contentTypeBuild.push(contentType?.__typename)
            try {
                if (contentType.__typename === 'ArticleList') {
                    const queryContent = require(`../components/${contentType.__typename}/${contentType.__typename}.query.js`);
                    const query = require(`../components/${contentType.__typename}/${contentType.__typename}Extended.query.js`);
                    componentQuery += queryContent + query;
                } else {
                    const fileContent = require(`../components/${contentType.__typename}/${contentType.__typename}.query.js`);
                    componentQuery += fileContent;
                }
            } catch {
                console.warn(`${contentTypes.__typename} component doesn't exist`);
            }
        }
    });

    return componentQuery;
};

export default buildComponentsQuery;
